<template>
  <div class="container">
    <div class="confirmation">
      <div class="confirmation__title">
        Thank you.
      </div>
      <div class="confirmation__text">
        We will get in touch with you very soon. If you want to pick a date for
        a quick demo,
      </div>
      <div class="confirmation__text">
        please click "Schedule a Demo" button below.
      </div>
      <div class="confirmation__btn">
        <a
          href="https://calendly.com/d/cmv-mf2-9zh/demo-of-impactfactors-vip-50-discount?month=2022-01"
          target="_blank"
          class="btn actions__btn actions__btn_get"
        >
          Schedule a Demo
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Confirmation',
};
</script>

<style lang="scss">
  @import 'assets/sass/utils/variables';

  .confirmation {
    text-align: center;
    padding-top: 80px;
    padding-bottom: 100px;

    &__title {
      font: 25px $font-global-bold;
      margin: 0 0 40px;
    }

    &__text {
      font: 25px / 1.5 $font-global
    }

    &__btn {
      margin-top: 40px;
      text-align: center;
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      padding: 0 15px;
      width: 155px;
      height: 40px;
      margin: 0 auto;
      background: $color-moody-blue;
      font-family: $font-global-medium, sans-serif;
      font-size: 16px;
      color: $color-white;
      border: 1px solid transparent;
      transition: opacity .2s ease-in, background .2s ease-in, color .2s ease-in;
      user-select: none;

      &:hover {
        opacity: .8;
      }

      &:active {
        background: $color-martinique;
        opacity: 1;
      }
    }

  }

</style>
